<!--
 * @Author: 秦少卫
 * @Date: 2022-09-03 19:16:55
 * @LastEditors: 秦少卫
 * @LastEditTime: 2023-02-26 21:53:40
 * @Description: 导入模板
-->

<template>
  <div style="display: inline-block">
    <Divider plain orientation="left">{{ $t('title_template') }}</Divider>
    <Tooltip
      :content="item.label"
      v-for="(item, i) in list"
      :key="i + '-bai1-button'"
      placement="top"
    >
      <img
        class="tmpl-img"
        :alt="item.label"
        v-lazy="item.src"
        @click="getTempData(item.tempUrl)"
      />
    </Tooltip>
  </div>
</template>

<script>
import select from '@/mixins/select';
import { downFontByJSON } from '@/utils/utils';

export default {
  name: 'ToolBar',
  mixins: [select],
  data() {
    return {
      jsonFile: null,
      list: [
        {
          label: '海报模板',
          tempUrl: './template/49234261-0187-4fdc-be80-f9dfb14c8bc6.json',
          src: './template/49234261-0187-4fdc-be80-f9dfb14c8bc6.png',
        },
        {
          label: '旅游海报',
          tempUrl: './template/6ff9093a-4976-416b-8285-db5496842487.json',
          src: './template/6ff9093a-4976-416b-8285-db5496842487.png',
        },
        {
          label: '邀请海报',
          tempUrl: './template/6b7bb836-4aaa-4dab-b715-26591d2253e5.json',
          src: './template/6b7bb836-4aaa-4dab-b715-26591d2253e5.png',
        },
        {
          label: '普通模板1',
          tempUrl: './template/4bdb1b1c-8f82-4120-b9da-edf91d92aaeb.json',
          src: './template/232ba8c0-af77-4523-ad56-ad03511621c8.png',
        },
      ],
    };
  },
  methods: {
    // 插入文件
    insertSvgFile() {
      this.$Spin.show({
        render: (h) => h('div', this.$t('alert.loading_fonts')),
      });

      downFontByJSON(this.jsonFile)
        .then(() => {
          this.$Spin.hide();
          this.canvas.c.loadFromJSON(this.jsonFile, () => {
            this.canvas.c.renderAll.bind(this.canvas.c);
            setTimeout(() => {
              const workspace = this.canvas.c.getObjects().find((item) => item.id === 'workspace');
              workspace.set('selectable', false);
              workspace.set('hasControls', false);
              this.canvas.c.requestRenderAll();
              this.canvas.editor.editorWorkspace.setSize(workspace.width, workspace.height);
              this.canvas.c.renderAll();
              this.canvas.c.requestRenderAll();
            }, 100);
          });
        })
        .catch(() => {
          this.$Spin.hide();
          this.$Message.error(this.$t('alert.loading_fonts_failed'));
        });
    },
    // 获取模板数据
    getTempData(tmplUrl) {
      this.$Spin.show({
        render: (h) => h('div', this.$t('alert.loading_data')),
      });
      const getTemp = this.$http.get(tmplUrl);
      getTemp.then((res) => {
        this.jsonFile = JSON.stringify(res.data);
        this.insertSvgFile();
      });
    },
  },
};
</script>

<style scoped lang="less">
.tmpl-img {
  width: 94px;
  cursor: pointer;
  margin-right: 5px;
}
</style>
