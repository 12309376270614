<!--
 * @Author: 秦少卫
 * @Date: 2022-09-03 19:16:55
 * @LastEditors: 秦少卫
 * @LastEditTime: 2023-02-26 21:13:28
 * @Description: 组合与拆分组合
-->

<template>
  <div class="attr-item box" v-if="isMultiple || isGroup">
    <!-- <ButtonGroup size="small" v-if="isMultiple || isGroup"> -->
    <!-- 组合按钮 多选时不可用 -->
    <Button v-if="isMultiple" :disabled="!isMultiple" @click="group" type="text">
      <svg
        t="1650848913991"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="17131"
        width="14"
        height="14"
      >
        <path
          d="M341.333333 341.333333 341.333333 512 554.666667 512 554.666667 341.333333 341.333333 341.333333M42.666667 42.666667 213.333333 42.666667 213.333333 85.333333 810.666667 85.333333 810.666667 42.666667 981.333333 42.666667 981.333333 213.333333 938.666667 213.333333 938.666667 810.666667 981.333333 810.666667 981.333333 981.333333 810.666667 981.333333 810.666667 938.666667 213.333333 938.666667 213.333333 981.333333 42.666667 981.333333 42.666667 810.666667 85.333333 810.666667 85.333333 213.333333 42.666667 213.333333 42.666667 42.666667M213.333333 810.666667 213.333333 853.333333 810.666667 853.333333 810.666667 810.666667 853.333333 810.666667 853.333333 213.333333 810.666667 213.333333 810.666667 170.666667 213.333333 170.666667 213.333333 213.333333 170.666667 213.333333 170.666667 810.666667 213.333333 810.666667M256 256 640 256 640 426.666667 768 426.666667 768 768 341.333333 768 341.333333 597.333333 256 597.333333 256 256M640 597.333333 426.666667 597.333333 426.666667 682.666667 682.666667 682.666667 682.666667 512 640 512 640 597.333333Z"
          p-id="17132"
        ></path>
      </svg>
      组合
    </Button>
    <!-- 拆分组合按钮，为单选且组元素时可用 -->
    <Button v-if="isGroup" :disabled="!isGroup" @click="unGroup" type="text">
      <svg
        t="1650848938557"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="17281"
        width="14"
        height="14"
      >
        <path
          d="M85.333333 85.333333 256 85.333333 256 128 554.666667 128 554.666667 85.333333 725.333333 85.333333 725.333333 256 682.666667 256 682.666667 384 768 384 768 341.333333 938.666667 341.333333 938.666667 512 896 512 896 768 938.666667 768 938.666667 938.666667 768 938.666667 768 896 512 896 512 938.666667 341.333333 938.666667 341.333333 768 384 768 384 682.666667 256 682.666667 256 725.333333 85.333333 725.333333 85.333333 554.666667 128 554.666667 128 256 85.333333 256 85.333333 85.333333M768 512 768 469.333333 682.666667 469.333333 682.666667 554.666667 725.333333 554.666667 725.333333 725.333333 554.666667 725.333333 554.666667 682.666667 469.333333 682.666667 469.333333 768 512 768 512 810.666667 768 810.666667 768 768 810.666667 768 810.666667 512 768 512M554.666667 256 554.666667 213.333333 256 213.333333 256 256 213.333333 256 213.333333 554.666667 256 554.666667 256 597.333333 384 597.333333 384 512 341.333333 512 341.333333 341.333333 512 341.333333 512 384 597.333333 384 597.333333 256 554.666667 256M512 512 469.333333 512 469.333333 597.333333 554.666667 597.333333 554.666667 554.666667 597.333333 554.666667 597.333333 469.333333 512 469.333333 512 512Z"
          p-id="17282"
        ></path>
      </svg>
      拆分组合
    </Button>
    <!-- </ButtonGroup> -->
  </div>
</template>

<script>
import select from '@/mixins/select';
// import { v4 as uuid } from 'uuid';

export default {
  name: 'ToolBar',
  mixins: [select],
  data() {
    return {};
  },
  computed: {
    // 单选且等于组元素
    isGroup() {
      return this.mSelectMode === 'one' && this.mSelectOneType === 'group';
    },
    // 是否为多选
    isMultiple() {
      return this.mSelectMode === 'multiple';
    },
  },
  methods: {
    // 拆分组
    unGroup() {
      this.canvas.editor.unGroup();
    },
    group() {
      this.canvas.editor.group();
    },
  },
};
</script>
<style scoped lang="less">
.box {
  display: flex;
  align-items: center;
  padding: 0;
  .ivu-btn {
    text-align: center;
    flex: 1;
  }
}
/deep/ .ivu-btn[disabled] {
  svg {
    opacity: 0.2;
  }
}
</style>
